import React from 'react'
import { Zoom } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'

import bg1 from '../../images/story/shape.png'
import bg2 from '../../images/story/shape2.png'
import bg3 from '../../images/story/shape3.png'

import bshape1 from '../../images/story/flower-shape1.svg'
import bshape2 from '../../images/story/flower-shape2.svg'
import bshape3 from '../../images/story/flower-shape3.svg'
import bshape4 from '../../images/story/flower-shape4.svg'

import imgJA1 from './ja1.jpeg'
import imgPessoaInteressante from './pessoa-interessante.jpg'
import imgPedidoNamoro1 from './pedido-namoro-1.jpeg'
import imgPedidoNamoro2 from './pedido-namoro-2.jpeg'
import imgPedidoCasamento from './balao.jpeg'


const Storys = [
    {
        image: imgJA1,
        bg: bg1,
        title: 'Como nos conhecemos',
        date: '30 de abril de 2022',
        description: 'Andre foi à igreja do Cidade das Flores para um programa JA, onde se viram pela primeira vez. Em seguida houve uma social na casa da Mylena e tiveram a primeira conversa casual.',
    },
    {
        image: imgPessoaInteressante,
        bg: bg2,
        title: 'Nossa, que pessoa interessante',
        date: '18 de junho de 2022',
        description: 'Novamente na casa da Mylena, depois de mais um JA, vários jovens ficaram conversando sobre diversos assuntos e ambos começaram a se enxergar com outros olhos, por causa de suas ideias.',
    },
    {
        image: imgPedidoNamoro1,
        bg: bg3,
        title: 'Quer namorar comigo? #1',
        date: '13 de agosto de 2022',
        description: 'Durante a programação do Mega Coral no dia 13, em um encontro escondido, o Andre pediu a Bruna em namoro.',
    },
    {
        image: imgPedidoNamoro2,
        bg: bg2,
        title: 'Quer namorar comigo? #2',
        date: '14 de agosto de 2022',
        description: 'Mas ficou com vergonha de um pedido tão xoxo, e no dia seguinte fez um pedido que não foi tão melhor mas pelo menos teve rosas. Essa é a data oficial porque sim.',
    },
    {
        image: imgPedidoCasamento,
        bg: bg1,
        title: 'O pedido de casamento',
        date: '18 de agosto de 2024',
        description: 'Passeio de balão é quase sinônimo de pedido de casamento, mas a Bruna nem desconfiou. Dessa vez teve rosas na primeira tentativa.',
    },
]


function StorySection2(props) {
    return (
        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nossa História'} MainTitle={'Como Aconteceu'} />
                <div className="wpo-story-wrap">
                    {
                        Storys.map((story, st) => (
                            <div className="wpo-story-item" key={st}>
                                <div className="wpo-story-img-wrap">
                                    <Zoom direction="up" duration="1000" triggerOnce="true">
                                        <div className="wpo-story-img">
                                            <img src={story.image} alt="" />
                                        </div>
                                    </Zoom>
                                    <div className="clip-shape">
                                        <svg viewBox="0 0 382 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                                        </svg>
                                    </div>
                                    <div className="wpo-img-shape">
                                        <img src={story.bg} alt="" />
                                    </div>
                                </div>
                                <div className="wpo-story-content">
                                    <div className="wpo-story-content-inner">
                                        <span>{story.date}</span>
                                        <h2>{story.title}</h2>
                                        <p>{story.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flower-shape-1">
                <img src={bshape1} alt="" />
            </div>
            <div className="flower-shape-2">
                <img src={bshape2} alt="" />
            </div>
            <div className="flower-shape-3">
                <img src={bshape3} alt="" />
            </div>
            <div className="flower-shape-4">
                <img src={bshape4} alt="" />
            </div>
        </section>
    )
}

export default StorySection2;