import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/Hero';
import CoupleSection2 from '../../components/CoupleSection2/CoupleSection2';
import StorySection2 from '../../components/StorySection2/StorySection2';
import PortfolioSection from '../../components/PortfolioSection';
import EventSection3 from '../../components/EventSection3/EventSection3';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import ListaPresentes from '../../components/ListaPresentes/ListaPresentes';

const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero />
            <a id="casal"><CoupleSection2 /></a>
            <a id="historia"><StorySection2 /></a>
            <PortfolioSection />
            <a id="presentes"><ListaPresentes /></a>
            <a id="local"><EventSection3 /></a>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;