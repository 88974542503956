import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ListItem from "@mui/material/ListItem";

const pages = [
    {
        title: "Início",
        link: "/#",
    },
    {
        title: "Casal",
        link: "/#casal",
    },
    {
        title: "História",
        link: "/#historia",
    },
    // {
    //     title: "Presença",
    //     link: "/#presenca",
    // },
    {
        title: "presentes",
        link: "/#presentes",
    },
    {
        title: "local",
        link: "/#local",
    },
]

export default function Header(props) {
    const [menuActive, setMenuState] = useState(false);

    return (

        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <div>
                                        <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                                            <div className="menu-close">
                                                <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                                            </div>

                                            <ul className="responsivemenu">
                                                {pages.map((item, idx) => (
                                                    <ListItem key={idx} onClick={() => {
                                                        window.location.href = item.link;
                                                        setMenuState(false);
                                                    }}>
                                                        <a href={item.link}>{item.title}</a>
                                                    </ListItem>
                                                ))}
                                            </ul>

                                        </div>

                                        <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                                            <button type="button" className="navbar-toggler open-btn">
                                                <span className="icon-bar first-angle"></span>
                                                <span className="icon-bar middle-angle"></span>
                                                <span className="icon-bar last-angle"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link className="navbar-brand" to="/home">Casal Soares</Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        {pages.map((item, idx) => (
                                            <li key={idx}><a href={item.link}>{item.title}</a></li>
                                        ))}
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}
