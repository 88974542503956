import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom'
import Team from '../../api/team.js';
import SectionTitle from '../SectionTitle'
import pImg3 from '../../images/pricing/shape-3.svg'
import pImg4 from '../../images/pricing/shape-4.svg'


const ListaPresentes = (props) => {
    const LinkDaLista = "https://www.mercadolivre.com.br/presentes/casamento-bruna-e-andre-dcupa";

    return (
        <section className='section-padding section-gifts'>
            <div className="container">
                <SectionTitle MainTitle={'Lista de Presentes'} />
                <div className="team-pg-area section-padding">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Caso você queira nos presentear, fizemos uma <a href={LinkDaLista} style={{
                                color: "#657150",
                                textDecoration: "underline",
                            }}>lista de presentes no Mercado Livre</a> com itens que pretendemos comprar em algum momento.</p>
                            <p>Não queremos que ninguém se sinta obrigado a dar presente, mas como alguns nos perguntaram o que poderiam dar, estamos disponibilizando opções aqui. Também existe no site a possibilidade de dar um valor em dinheiro.</p>
                            <p>Caso enfrente problemas pra "fechar o pedido" no site, por favor entre em contato para oferecermos um plano B.</p>
                            <p>Nao se esqueça: nosso maior presente é ter o carinho e a companhia de vocês neste momento especial!</p>
                        </div>
                    </div>
                    <div className='align-center'>
                        <a class="theme-btn" href={LinkDaLista}>Acessar Lista</a>
                    </div>
                </div>
            </div>
            <div className="left-shape"><img src={pImg3} alt="" /></div>
            <div className="right-shape"><img src={pImg4} alt="" /></div>
        </section>
    )
};
export default ListaPresentes;
